$filter-block-width: 1440px; // use unset for a full width
$filters-body-width: $filter-block-width;
@import '../theme-bootstrap';

.filterable-products {
  &-block {
    max-width: $filter-block-width;
    margin: 0 auto;
    &--filters {
      max-width: $filters-body-width;
      margin: 20px auto 5px;
    }
  }
  &__filter-arrow {
    &:before {
      content: '';
      border: solid $color--white;
      border-width: 0 2.4px 2.4px 0;
      height: 5px;
      width: 5px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      bottom: 22px;
      #{$rdirection}: 20px;
      font-size: 5px;
      position: absolute;
      @include breakpoint($bp--xlarge-up) {
        display: none;
      }
    }
    &.open {
      &:before {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
    }
  }
  &__filter-set {
    background: $color--white;
    width: 100%;
    display: flex;
    @include breakpoint($bp--xlarge-up) {
      padding-top: 0;
    }
    &.filter-theme--dark,
    &.filter-theme--light {
      background: transparent;
    }
    &.filtered {
      .filterable-products__filter-set-footer {
        width: 105px;
        float: #{$rdirection};
        @include breakpoint($bp--xlarge-up) {
          width: auto;
          #{$rdirection}: 0;
          float: none;
          padding: 10px 0 0;
        }
        .reset-link,
        .filterable-products_product-count {
          display: inline-block;
        }
        .filterable-products_product-count span:first-child {
          margin-#{$ldirection}: 5px;
          &::before {
            content: '(';
          }
        }
        .filterable-products_product-count span:last-child {
          &::after {
            content: ')';
          }
        }
      }
    }
    &-label {
      font-family: $ano-bold_regular-font;
      text-transform: uppercase;
      font-size: 18px;
      width: calc(100% - 20px);
      position: absolute;
      #{$rdirection}: 10px;
      top: 10px;
      height: 50px;
      background-color: $color--black;
      text-align: center;
      padding-top: 10px;
      padding-#{$rdirection}: 10px;
      color: $color--white;
      @if $fonts_update == true {
        @include h6();
        @include swap_direction(padding, 4px 20px 0 32px);
        line-height: 42px;
        margin: 0;
        text-align: $ldirection;
      }
      @include breakpoint($bp--xlarge-up) {
        background: none;
        color: $color--black;
        height: auto;
        min-width: 100px;
        padding: 0;
        position: static;
        width: auto;
        @if $fonts_update == true {
          @include h4();
          font-size: 15px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
      .filters_container--inner.has-sorting & {
        width: 45%;
        width: calc(50% - 16.5px);
        margin-#{ldirection}: 6.5px;
        @include breakpoint($bp--xlarge-up) {
          width: auto;
        }
        :not(.has-image-toggle) & {
          @include breakpoint($bp--xlarge-up) {
            min-width: unset;
          }
        }
      }
    }
    &-container {
      width: 100%;
      position: relative;
      &--inner {
        .filters-position-center & {
          @include breakpoint($bp--xlarge-up) {
            display: flex;
            justify-content: space-around;
          }
        }
      }
    }
    &-body {
      display: flex;
      justify-content: space-around;
      position: relative;
      min-height: 80px;
      @include breakpoint($bp--medium-up) {
        height: 120px;
      }
      @include breakpoint($bp--xlarge-up) {
        justify-content: space-between;
        height: auto;
        min-height: unset;
      }
      &.multi-rows {
        flex-wrap: wrap;
      }
    }
    &-footer {
      width: 100%;
      padding: 5px 20px;
      min-height: 28px;
      text-align: #{$rdirection};
      @include breakpoint($bp--xlarge-up) {
        padding: 10px 0 0;
        bottom: 0;
        position: absolute;
      }
      .reset-link {
        display: none;
        color: $color--gray--lighter;
        text-transform: inherit;
      }
    }
    &-section {
      font-size: 16px;
      display: flex;
      padding: 0 3px;
      color: $color--gray--lighter;
      border-bottom: 1px solid $color--gray--lighter;
      flex-grow: 1;
      flex-basis: auto;
      padding-bottom: 15px;
      height: 40px;
      @if $fonts_update == true {
        @include h6();
      }
      @include breakpoint($bp--medium-up) {
        height: 60px;
      }
      @include breakpoint($bp--xlarge-up) {
        font-family: $ano-bold_regular-font;
        font-size: 18px;
        height: auto;
        padding-bottom: 25px;
        border: none;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-#{$ldirection}: 25px;
        margin-#{$rdirection}: 40px;
        @if $fonts_update == true {
          @include h6();
          font-size: 18px;
        }
      }
      @include breakpoint($bp--xxlarge-up) {
        flex-wrap: nowrap;
        justify-content: center;
        margin-#{$ldirection}: 0;
        margin-#{$rdirection}: 0;
        font-size: 19px;
        @if $fonts_update == true {
          font-size: 18px;
        }
      }
      .filters-position-center & {
        margin-#{$ldirection}: 20px;
      }
      &.first {
        @include breakpoint($bp--xxlarge-up) {
          justify-content: flex-start;
        }
        .filters-position-center & {
          margin-#{$ldirection}: 0;
        }
      }
      &.last {
        @include breakpoint($bp--xlarge-up) {
          justify-content: flex-end;
        }
      }
      &.current {
        color: $color--black;
        border-bottom: 2px solid $color--black;
        @include breakpoint($bp--xlarge-up) {
          border: none;
        }
        .filterable-products-filter-set {
          &__filters {
            display: flex;
            position: absolute;
            justify-content: space-evenly;
            bottom: 0;
            #{$ldirection}: 0;
            min-width: 100%;
            &.draggable {
              transition: transform 500ms ease 0s;
              touch-action: pan-y;
              .filterable-products-filter-set__button:last-of-type {
                margin-#{$rdirection}: 10px;
              }
            }
            @include breakpoint($bp--xlarge-up) {
              position: static;
              width: auto;
              justify-content: flex-start;
            }
          }
          &__filters-wrapper {
            position: absolute;
            bottom: 0;
            .mpp-custom-override-layout & {
              bottom: -35px;
            }
            #{$ldirection}: 0;
            width: 100%;
            height: 45px;
            @include breakpoint($bp--xlarge-up) {
              position: static;
              height: auto;
              margin-#{$ldirection}: 10px;
            }
            .expanded & {
              @include breakpoint($bp--xlarge-up) {
                margin-#{$ldirection}: 0;
                padding-top: 10px;
              }
            }
          }
        }
      }
      &.row {
        @include breakpoint($bp--xlarge-up) {
          padding-bottom: 0;
          justify-content: center;
        }
      }
      .filterable-products-filter-set {
        &__filters {
          display: none;
          @include breakpoint($bp--xlarge-up) {
            display: flex;
            margin-#{$ldirection}: 10px;
          }
          &-wrapper {
            margin: 0;
          }
        }
        &__title {
          padding: 0 3px;
          text-transform: uppercase;
          font-family: $ano-bold_regular-font;
          margin: 0 auto;
          display: none;
          @include breakpoint($bp--xlarge-up) {
            display: block;
            margin: 0;
            color: $color--black;
            margin-#{$ldirection}: 8px;
            padding-bottom: 5px;
          }
          &--mobile {
            display: block;
            @include breakpoint($bp--medium-up) {
              font-size: 23px;
            }
            @include breakpoint($bp--xlarge-up) {
              display: none;
            }
          }
          @include breakpoint($bp--xxlarge-up) {
            margin-#{$ldirection}: 0;
            padding-bottom: 0;
            white-space: nowrap;
          }
        }
        &__button {
          padding: 0 20px;
          white-space: nowrap;
          cursor: pointer;
          font-size: 14px;
          line-height: 25px;
          color: $color--gray--lighter;
          border-color: $color--gray--lighter;
          font-family: $roboto-mono_regular-font;
          @include breakpoint($bp--medium-up) {
            font-size: 20px;
          }
          @include breakpoint($bp--xlarge-up) {
            font-family: $ano-bold_regular-font;
            border-bottom: 1px solid;
            padding: 1px 10px 15px;
            font-size: 14px;
            text-transform: uppercase;
            &:first-child {
              padding-#{$ldirection}: 0;
            }
            &:last-child {
              padding-#{$rdirection}: 0;
            }
          }
          @include breakpoint($bp--large-up) {
            font-size: 18px;
          }
          &.active,
          &.hover {
            color: $color--black;
            border-color: $color--black;
          }
          border: none;
          &.disabled {
            cursor: default;
          }
          &.busy {
            opacity: 0.5;
            cursor: wait;
          }
        }
      }
    }
  }
}

.filters_container {
  display: flex;
  width: 100%;
  padding-top: 20px;
  position: relative;
  flex-direction: column;
  .mpp-custom-override-layout & {
    @include breakpoint($bp--xlarge-up) {
      padding-top: 53px;
    }
  }
  @include breakpoint($bp--xlarge-up) {
    flex-direction: row;
  }
  &--inner {
    display: flex;
    width: 100%;
    max-width: calc(#{$site-width} - 20px); // keep same as grid
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    @include breakpoint($bp--xlarge-up) {
      flex-direction: row;
    }
    &.has-sorting,
    &.has-filters {
      min-height: 65px;
    }
    .filters-position-center & {
      display: flex;
      justify-content: space-around;
    }
  }
  .mpp-sort-wrapper {
    @include breakpoint($bp--xlarge-up) {
      width: 25%;
    }
  }
  &.expanded {
    min-height: 200px;
    .filterable-products__wrapper {
      @include breakpoint($bp--xlarge-up) {
        z-index: 2;
        height: auto;
      }
    }
    .filterable-products__filter-set-section {
      .filterable-products-filter-set__filters-wrapper {
        display: none;
      }
      &.current {
        .filterable-products-filter-set__filters-wrapper {
          display: block;
        }
      }
    }
  }
  &.fixed {
    @include breakpoint($bp--xlarge-up) {
      position: fixed;
      height: 90px;
      border-bottom: 1px solid $color--gray--lighter;
      background-color: $color--white;
      #{$ldirection}: 0;
      width: 100%;
      z-index: 999;
    }
    .mpp-custom-override-layout & {
      padding-top: 20px;
      @include breakpoint($bp--xlarge-up) {
        padding-top: 30px;
      }
    }
  }
  &.closed {
    .filterable-products__wrapper {
      height: auto;
    }
    .filterable-products-filter-set__filters-wrapper {
      display: none;
    }
  }
  &.fixed {
    &.closed {
      .filterable-products__wrapper {
        .filterable-products__filter-set-container--inner {
          .filterable-products__filter-set-footer {
            bottom: 28px;
          }
        }
      }
    }
  }
  &.filtered {
    .filterable-products__wrapper {
      .filterable-products__filter-set-footer {
        .reset-link,
        .filterable-products_product-count {
          display: block;
          float: #{$ldirection};
        }
        .filterable-products_product-count span:first-child {
          margin-#{$ldirection}: 5px;
          &::before {
            content: '(';
          }
        }
        .filterable-products_product-count span:last-child {
          &::after {
            content: ')';
          }
        }
      }
    }
    .mpp-model-images__container {
      margin: 20px auto 25px;
      @include breakpoint($bp--xlarge-up) {
        @include swap_direction(margin, 0 0 20px 0);
      }
    }
  }
  .mpp-model-images {
    &__container {
      font-family: $roboto-mono_bold-font;
      color: $color--gray--lighter;
      display: flex;
      font-size: 19px;
      height: 60px;
      justify-content: flex-start;
      margin: 20px auto 0;
      position: relative;
      max-width: 1440px;
      width: 350px;
      order: 2;
      @include breakpoint($bp--medium-up) {
        font-size: 20px;
        width: 95%;
      }
      @include breakpoint($bp--largest-up) {
        justify-content: flex-start;
        order: 0;
        width: 25%;
        font-size: 18px;
        @include swap_direction(margin, 0 0 20px 0);
      }
    }
    &__label {
      display: none;
      @include breakpoint($bp--xlarge-up) {
        color: $color--black;
        display: block;
        font-family: $ano-bold_regular-font;
        @include swap_direction(padding, 0 10px 0);
        text-transform: uppercase;
        @if $fonts_update == true {
          @include h4();
          font-size: 15px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
      @include breakpoint($bp--xxlarge-up) {
        @include swap_direction(padding, 0 10px 0 0);
      }
    }
    &__option {
      display: flex;
      padding: 0 3px;
      color: $color--gray--lighter;
      border-bottom: 1.5px solid $color--gray--lighter;
      font-size: 20px;
      font-family: $ano-bold_regular-font;
      flex-grow: 1;
      flex-basis: auto;
      margin-bottom: 10px;
      padding-bottom: 15px;
      height: 35px;
      text-transform: uppercase;
      @if $fonts_update == true {
        @include h6();
        letter-spacing: 0;
      }
      @include breakpoint($bp--xlarge-up) {
        cursor: pointer;
        padding: 0 10px 15px;
        font-size: 18px;
        flex-grow: 0;
        @if $fonts_update == true {
          font-size: 15px;
        }
      }
      &:hover,
      &.selected {
        color: $color--black;
        border-color: $color--black;
      }
      span {
        margin: 0 auto;
      }
    }
  }
  .mpp-model-images__container ~ .filterable-products__wrapper {
    margin-bottom: 0;
  }
  .filterable-products__wrapper {
    width: 100%;
    min-height: 80px;
    margin-bottom: 80px;
    @include breakpoint($bp--xlarge-up) {
      height: auto;
      max-width: 40%;
      width: auto;
      margin: 0 auto;
      @if $fonts_update == true {
        max-width: 48%;
      }
    }
    .contextual-links-region {
      position: static;
    }
    .filterable-products {
      &__filter-set {
        width: 100%;
        &-container {
          position: static;
          @include breakpoint($bp--xlarge-up) {
            width: 100%;
            height: auto;
          }
        }
        &-section {
          display: none;
          flex-wrap: wrap;
          justify-content: space-around;
          padding-bottom: 0;
          border-bottom: none;
          margin-top: 75px;
          @include breakpoint($bp--xlarge-up) {
            display: flex;
            // padding: 0 18px;
            padding-#{$rdirection}: 18px;
            margin-top: 0;
            margin-#{$rdirection}: 0;
            margin-#{$ldirection}: 0;
          }
          &.closed {
            display: none;
          }
          &.open {
            display: flex;
          }
          .filterable-products-filter-set {
            &__button {
              border-bottom: none;
              color: $color--gray--lighter;
              font-family: $roboto-mono_regular-font;
              text-transform: lowercase;
              font-size: 15px;
              outline: none;
              @if $fonts_update == true {
                font-family: $helvetica-font;
              }
              &:not(.disabled) {
                .mpp-custom-override-layout & {
                  color: $color--black;
                }
              }
            }
            &__title {
              color: $color--gray--lighter;
              cursor: pointer;
              font-size: 18px;
              #{$ldirection}: auto;
              display: block;
              height: 40px;
              @if $fonts_update == true {
                @include h6();
              }
              &.filterable-products-filter-set--has-image {
                .has-image-toggle & {
                  display: none;
                }
                &.show {
                  .has-image-toggle & {
                    @include breakpoint($bp--xlarge-up) {
                      display: block;
                    }
                  }
                }
              }
              &.selected {
                color: $color--black;
              }
              &--mobile {
                display: none;
                &.filterable-products-filter-set--has-image {
                  &.show {
                    display: block;
                    .has-image-toggle & {
                      @include breakpoint($bp--xlarge-up) {
                        display: none;
                      }
                    }
                  }
                }
              }
              &.filtered {
                color: $color--black;
              }
            }
          }
          .vertical-line {
            #{$ldirection}: 0;
            border-#{$ldirection}: 1px solid $color--gray--lighter;
            border-#{$rdirection}: none;
            padding-#{$rdirection}: 18px;
            height: 15px;
            margin-top: 6px;
            @if $fonts_update == true {
              margin-top: 2px;
            }
            &.filterable-products-filter-set--has-image {
              .has-image-toggle & {
                display: none;
              }
              &.show {
                .has-image-toggle & {
                  display: block;
                }
              }
            }
          }
          &.last {
            border-#{$rdirection}: none;
          }
          &.first {
            padding-#{$ldirection}: 18px;
            .vertical-line {
              display: none;
              &.filterable-products-filter-set--has-image {
                &.show {
                  .has-image-toggle & {
                    display: none;
                  }
                }
              }
            }
          }
          .filterable-products-filter-set {
            &__filters {
              margin-bottom: 10px;
              @include breakpoint($bp--xlarge-up) {
                position: absolute;
                width: 100%;
                margin-#{$ldirection}: 0;
              }
              &-wrapper {
                display: none;
              }
              .has-smooshes & {
                @include breakpoint($bp--xlarge-up) {
                  padding-top: 5px;
                }
              }
            }
            &__button {
              padding: 0 15px;
              @include breakpoint($bp--xlarge-up) {
                width: auto;
                min-width: unset;
              }
              &.active,
              &.hover {
                color: $color--black;
                .mpp-custom-override-layout & {
                  font-family: $roboto-mono_bold-font;
                  line-height: 27px;
                }
              }
              &-wrapper {
                &-smoosh {
                  width: 75px;
                  height: 85px;
                  margin: 0 auto;
                }
                &-label {
                  text-align: center;
                  margin-bottom: 20px;
                  @include breakpoint($bp--xlarge-up) {
                    margin-bottom: 0;
                  }
                }
                &-label:only-child {
                  margin-top: 10px;
                  @include breakpoint($bp--xlarge-up) {
                    margin-top: 50px;
                    padding: 0 15px;
                  }
                  .has-smooshes & {
                    margin-top: 45px;
                    margin-bottom: 60px;
                    @include breakpoint($bp--xlarge-up) {
                      margin-top: 45px;
                      margin-bottom: 40px;
                    }
                  }
                }
              }
            }
          }
          &.current {
            .filterable-products-filter-set {
              &__filters {
                bottom: auto;
                justify-content: space-evenly;
              }
            }
          }
        }
        &-body {
          min-height: 80px;
          @include breakpoint($bp--xlarge-up) {
            height: auto;
            min-height: unset;
          }
        }
        &-footer {
          font-size: 13px;
          padding: 0;
          z-index: 9999;
          position: absolute;
          bottom: 0;
          width: auto;
          #{$rdirection}: 10px;
          @include breakpoint($bp--xlarge-up) {
            bottom: 0;
            #{$rdirection}: 0;
          }
          .reset-link {
            float: #{$ldirection};
            display: none;
            color: $color--black;
            border-bottom-color: $color--black;
            &.busy {
              opacity: 0.5;
              cursor: wait;
            }
          }
          .filterable-products_product-count {
            display: none;
          }
        }
      }
    }
  }
}

.filterable-products__filter-set-section.first.last {
  justify-content: center;
}

.filter-layout--pills {
  &.filterable-products__filter-set {
    &.filter-theme--dark {
      .filterable-products-filter-set {
        &__button {
          &.active {
            @include breakpoint($bp--xlarge-up) {
              background-color: $color--white;
            }
          }
        }
        &__filters {
          @include breakpoint($bp--xlarge-up) {
            border: 1px solid $color--white;
          }
        }
      }
    }
    .filterable-products__filter-set-footer {
      display: none;
    }
    .filterable-products-filter-set {
      &__filters {
        @include breakpoint($bp--xlarge-up) {
          border-radius: 50px;
          border: 1px solid $color--black;
        }
      }
      &__filters-wrapper {
        bottom: -11px;
        @include breakpoint($bp--xlarge-up) {
          bottom: 0;
        }
      }
      &__title {
        @include body-text--small-bold;
        padding: 10px 0;
        @include breakpoint($bp--xlarge-up) {
          padding: 17px 0;
        }
      }
      &__button {
        @include body-text--small-bold;
        padding: 16px;
        min-width: 124px;
        text-align: center;
        border-bottom: 1px solid $color--very--light--grey;
        transition: all 0.5s ease-in-out;
        @include breakpoint($bp--xlarge-up) {
          color: $color--black;
          border: 1px solid transparent;
          border-radius: 50px;
        }
        &.hover {
          border-color: transparent;
        }
        &.active {
          border-bottom-color: $color--black;
          @include breakpoint($bp--xlarge-up) {
            background-color: $color--black;
            color: $color--white;
          }
        }
      }
    }
    .filterable-products__filter-set-section.current {
      .filterable-products-filter-set__filters.draggable {
        .filterable-products-filter-set__button {
          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }
  .filterable-products__filter-set-section {
    .blur-last-filter {
      pointer-events: none;
      position: absolute;
      display: none;
      width: 100px;
      height: 40px;
      #{$rdirection}: 0;
      bottom: 3px;
      background: linear-gradient(to $rdirection, $color--transparent, $color--white);
    }
    &.current {
      .blur-last-filter {
        display: block;
      }
      .remove-blur {
        .blur-last-filter {
          display: none;
        }
      }
    }
  }
  &.filter-theme--dark {
    .blur-last-filter {
      background: linear-gradient(to $rdirection, $color--transparent, $color--black);
    }
  }
}

.filter-theme--dark {
  &.filterable-products__filter-set {
    .filterable-products__filter-set-section.current {
      border-bottom-color: $color--white;
      .filterable-products-filter-set__title {
        opacity: 1;
      }
    }
    .filterable-products-filter-set {
      &__title,
      &__button {
        color: $color--white;
        opacity: 0.5;
        @include breakpoint($bp--xlarge-up) {
          opacity: 1;
        }
        &.active {
          opacity: 1;
          border-bottom-color: $color--white;
          @include breakpoint($bp--xlarge-up) {
            color: $color--black;
          }
        }
      }
    }
  }
}
